<div class="wrapper">
  <div class="first-container">
    <img class="image" src="assets/images/hello_vchry.svg" alt="hello-vchry">
    <div class="description">
      <h1>ووچـری همیشه همراه شمـا</h1>
      <h3>با پشتیبان و مشاور خود در ووچری برای رفع
        هرگـونـه سـوال و مشکل در ارتبـاط باشیــد.</h3>
      <a href="mailto:support@vocherry.com" class="send-message">
        ارسال پیام
        <mat-icon>chevron_left</mat-icon>
      </a>
    </div>
  </div>
</div>

<div id="section-hero" class="wrapper">
  <div class="illustration-container relative">
    <img class="w-full" src="assets/images/illustration.png">
    <img class="illustration-bg-card" src="assets/images/illustration-bg.png">
    <img class="illustration-bubble float-animation-1 left-[3%] top-[10%]" src="assets/images/bubble-1.png">
    <img class="illustration-bubble float-animation-2 right-[3%] top-[10%]" src="assets/images/bubble-2.png">
    <img class="illustration-bubble float-animation-3 -top-[15%] right-[25%]" src="assets/images/bubble-3.png">
    <img class="illustration-bubble float-animation-4 -top-[15%] left-[25%]" src="assets/images/bubble-4.png">
  </div>
  <div>
    <div class="branding">
      <div class="description">
        <h1>آسان‌ترین تجربه‌ی خرید ووچر</h1>
        <h3>
          <span class="styled">ووچـری،</span>
          برای راحتی و دسترسی  ایرانیان به تبادلات مالی جهـانی
        </h3>
      </div>
      <div class="download-cta">
        <button mat-flat-button color="primary" class="cursor-pointer">دانلود اپلیکیشن</button>
        <div class="h-[58px] w-auto relative">
          <mat-icon class="transition duration-300 opacity-100 hover:opacity-0" svgIcon="qr_code" matRipple />
          <mat-icon class="!absolute right-0 transition duration-300 opacity-0 hover:opacity-100 cursor-pointer" svgIcon="qr_code_white" color="danger" matRipple (click)="toggleQRCode()" />
          @if(showQRCode()) {
            <div class="rounded-lg mx-4 size-48 absolute right-full top-1/2 -translate-y-1/2 transition duration-300 bg-gray-400"></div>
          }
        </div>
      </div>
    </div>
    <div class="qr-code">
      <p>برای دانلود نسخه اندروید، کد QR را اسکن کنید</p>
      <img src="/assets/images/qr_code.svg" alt="Download QR Code">
    </div>
    <div class="download-markets">
      <mat-icon svgIcon="bazar" />
      <mat-icon svgIcon="sib_che" />
      <mat-icon svgIcon="play_store" />
      <mat-icon svgIcon="apk" />
    </div>
  </div>
</div>

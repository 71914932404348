<div class="scroll">
  <img src="assets/images/partners/amarkets.svg" alt="amarkets">
  <img src="assets/images/partners/perfect-money.svg" alt="perfect-money">
  <img src="assets/images/partners/paysafe-card.svg" alt="paysafe">
  <img src="assets/images/partners/alpari.svg" alt="alpari">
  <img src="assets/images/partners/amarkets.svg" alt="amarkets">
  <img src="assets/images/partners/perfect-money.svg" alt="perfect-money">
  <img src="assets/images/partners/paysafe-card.svg" alt="paysafe">
  <img src="assets/images/partners/alpari.svg" alt="alpari">
  <img src="assets/images/partners/amarkets.svg" alt="amarkets">
  <img src="assets/images/partners/perfect-money.svg" alt="perfect-money">
  <img src="assets/images/partners/paysafe-card.svg" alt="paysafe">
  <img src="assets/images/partners/alpari.svg" alt="alpari">
  <img src="assets/images/partners/amarkets.svg" alt="amarkets">
  <img src="assets/images/partners/perfect-money.svg" alt="perfect-money">
  <img src="assets/images/partners/paysafe-card.svg" alt="paysafe">
  <img src="assets/images/partners/alpari.svg" alt="alpari">
</div>

<div id="services-section" class="w-full relative">
  <div id="why-vouchery-anchor" class="absolute -top-8"></div>
  <div id="trigger-apps-1" class="w-full absolute h-[80vh]"></div>
  <div id="trigger-apps-2" class="w-full absolute h-[23vh] top-[81vh]"></div>
  <div class="sticky top-10 grid grid-cols-1 md:grid-cols-12 md:grid-rows-2 gap-4 md:gap-12 overflow-visible">
    <div class="col-span-7 md:col-span-5 md:row-span-1 lg:row-span-2 relative overflow-visible">
      <div id="services-title"
           class="w-full sm:mt-6 md:mt-20 lg:mt-0 flex flex-col gap-4 items-center justify-center md:items-start h-full md:pb-24">
        <p class="font-normal text-sm">نگران سختـی و کندی مبادلات ارزی بین‌المللی‌تان هستید؟</p>
        <h2 class="font-medium text-xl lg:text-2xl xl:text-3xl text-center md:text-start">
          ووچـــری، ویتــریـنی از راه‌حــل‌ها
          <br class="hidden md:block"/>
          برای مشکلات انتقالات مالی ایرانیان
        </h2>
        <div class="hidden md:block">
          <ng-container [ngTemplateOutlet]="GoToDownloadBtnRef"/>
        </div>
      </div>
    </div>
    <div id="services" class="col-span-7 md:row-span-2 flex flex-col gap-4">
      @for (service of services; track service) {
        @defer (on viewport) {
          <div [@fadeSlideUp] class="card relative bg-slate-300/[.4] backdrop-blur-lg">
            <img [src]="service.icon" [alt]="service.description" class="size-20">
            <p>{{ service.description }}</p>
            @if (service.sendMessageLink) {
              <a id="send-message" class="send-message whitespace-nowrap hover:text-[var(--c-secondary-80)]" href="mailto:support@vocherry.com">
                ارسال پیام
                <mat-icon>chevron_left</mat-icon>
              </a>
            }
            <div id="sec{{$index + 1}}"
                 class="-z-10 h-full w-full absolute left-0 top-0"></div>
          </div>
        } @placeholder {
          <div class="h-32"></div>
        }
      }
    </div>
    <div class="col-span-7 flex justify-center md:hidden md:row-span-1">
      <ng-container [ngTemplateOutlet]="GoToDownloadBtnRef"/>
    </div>
  </div>
</div>

<ng-template #GoToDownloadBtnRef>
  <button mat-flat-button color="primary" class="!py-3 !px-24 !text-[var(--c-secondary-0)] !h-14">دانلود اپلیکیشن
  </button>
</ng-template>

<app-navigation>
  <div>
    <section-hero class="m-auto max-w-[1440px] sections-wrapper" />
    <section-services class="m-auto max-w-[1440px] sections-wrapper" />
    <div id="download-links-trigger">
      <section-why class="m-auto max-w-[1440px] sections-wrapper" />
      <section-steps class="m-auto max-w-[1440px] sections-wrapper" />
      <section-support class="m-auto max-w-[1440px] sections-wrapper" />
      <section-partners />
      <app-footer />
    </div>
  </div>
  <app-download-links />
</app-navigation>

<div class="footer-wrapper">
  <div class="logo-container">
    <img src="assets/images/logo-white.svg" alt="logo">
    <span>آسـان ترین تجـربه خرید ووچـر</span>
    <div class="download-button-container">
      <img src="assets/images/google-play-btn.svg" alt="logo">
      <img src="assets/images/app-store-btn.svg" alt="logo">
    </div>
  </div>

  <div class="bottom-container">
    <div class="enamad">
      <img src="assets/images/enamad.svg" alt="enamad">
    </div>
    <div class="rights">
      <span>© 2024 Voucherry. All rights reserved.</span>
    </div>
  </div>
</div>

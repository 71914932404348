import {Component, Inject, PLATFORM_ID, signal, ViewEncapsulation} from '@angular/core';
import { NavigationComponent } from '@app/components/navigation/navigation.component';
import { SectionHeroComponent } from '@app/components/section-hero/section-hero.component';
import { SectionServicesComponent } from '@app/components/section-services/section-services.component';
import { SectionStepsComponent } from '@app/components/section-steps/section-steps.component';
import { SectionSupportComponent } from "@app/components/section-support/section-support.component";
import { SectionPartnersComponent } from "@app/components/section-partners/section-partners.component";
import { FooterComponent } from "@app/components/footer/footer.component";
import {SectionWhyComponent} from "@app/components/section-why/section-why.component";
import {DownloadLinksComponent} from "@app/components/download-links/download-links.component";
import {gsap} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import {isPlatformBrowser} from "@angular/common";

gsap.registerPlugin(ScrollTrigger);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    NavigationComponent,
    SectionHeroComponent,
    SectionServicesComponent,
    SectionStepsComponent,
    SectionSupportComponent,
    SectionPartnersComponent,
    FooterComponent,
    SectionWhyComponent,
    DownloadLinksComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export default class AppComponent {
  constructor(@Inject(PLATFORM_ID) platformId: object) {
    isBrowser.set(isPlatformBrowser(platformId));
  }
}

export const isBrowser = signal(false);

import {Component, ViewEncapsulation} from '@angular/core';
import {MatPrefix} from "@angular/material/form-field";
import {MatIcon} from "@angular/material/icon";

@Component({
  selector: 'section-support',
  standalone: true,
  templateUrl: './section-support.component.html',
  styleUrl: './section-support.component.scss',
  imports: [
    MatPrefix,
    MatIcon
  ],
  encapsulation: ViewEncapsulation.None,
})
export class SectionSupportComponent {

}

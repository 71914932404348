import { Component, ViewEncapsulation } from '@angular/core';
import { MatButton } from '@angular/material/button';

interface IService {
  icon: string;
  title: string;
  description: string;
}

@Component({
  selector: 'section-steps',
  standalone: true,
  imports: [
    MatButton
  ],
  templateUrl: './section-steps.component.html',
  styleUrl: './section-steps.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SectionStepsComponent {
  ourServices: IService[] = [
    {
      icon: '/assets/icons/registration_form.svg',
      title: 'ثبت نام کنید',
      description: 'در اپلیکیشن ووچری ثبت نام کنید',
    },
    {
      icon: '/assets/icons/kyc.svg',
      title: 'احراز هویت کنید',
      description: 'برای حفظ امنیت حساب‌تان احراز هویت کنید',
    },
    {
      icon: '/assets/icons/buy_voucher.svg',
      title: 'خرید ووچر',
      description: 'ووچر دلخواه‌تان را به راحتی بخرید!',
    }
  ];
}

@if (isHandset$ | async) {
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav position="start" #drawer class="sidenav" fixedInViewport [attr.role]="'dialog'" [mode]="'over'" [opened]="false">
      <ng-template [ngTemplateOutlet]="sidenavMenu" [ngTemplateOutletContext]="{ drawer: drawer }"/>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="top-toolbar">
        <ng-template [ngTemplateOutlet]="mobileToolbar" [ngTemplateOutletContext]="{ drawer: drawer }" />
      </mat-toolbar>
      <ng-template [ngTemplateOutlet]="content" />
    </mat-sidenav-content>
  </mat-sidenav-container>
} @else {
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav-content>
      <mat-toolbar class="top-toolbar">
        <ng-template [ngTemplateOutlet]="desktopToolbar" />
      </mat-toolbar>
      <ng-template [ngTemplateOutlet]="content" />
    </mat-sidenav-content>
  </mat-sidenav-container>
}

<ng-template #sidenavMenu let-drawer="drawer">
  <div class="menu-wrapper">
    <mat-toolbar>
      <button mat-icon-button (click)="drawer.toggle()">
        <mat-icon svgIcon="close_outlined" />
      </button>
    </mat-toolbar>
    <mat-nav-list>
      <a mat-list-item href="#services-section-anchor" (click)="drawer.toggle()">
        خدمات
      </a>
      <a mat-list-item href="#why-vouchery-anchor" (click)="drawer.toggle()">
        چرا ووچری
      </a>
    </mat-nav-list>
    <hr />
    <div class="download-place">
      <p>
        دانلود اپلیکیشن
      </p>
      <div class="downloads">
        <img src="./assets/images/market-download/playstore.svg" alt="Download app from google PlayStore">
        <img src="./assets/images/market-download/apk.svg" alt="Download APK directly">
        <img src="./assets/images/market-download/bazaar.svg" alt="Download from Bazaar">
        <img src="./assets/images/market-download/sibche.svg" alt="Download from SibChe">
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mobileToolbar let-drawer="drawer">
  <button
    type="button"
    aria-label="Toggle sidenav"
    mat-icon-button
    (click)="drawer.toggle()">
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <img src="assets/images/logo.svg" alt="VouCherry Logo" />
</ng-template>

<ng-template #desktopToolbar>
  <div class="download">
    <button mat-flat-button>
      <span>دانلود اپلیکیشن</span>
      <mat-icon svgIcon="arrow_down_outline" />
    </button>
  </div>
  <div class="logo-links">
    <nav class="links">
      <a mat-flat-button href="#services-section-anchor" class="link-item">خدمات</a>
      <a mat-flat-button href="#why-vouchery-anchor" class="link-item">چرا ووچری</a>
    </nav>
    <img src="assets/images/logo.svg" alt="VouCherry Logo" />
  </div>
</ng-template>

<ng-template #content>
  <div class="content-wrapper">
    <ng-content />
  </div>
</ng-template>

<div id="services-section-anchor" class="absolute -top-8"></div>
@for (provider of providers; track provider.name; let i = $index) {
  <div class="card {{provider.featured && 'premium'}} item-{{i}}"
       [ngClass]="{
       'opacity-0': !animationEnded(),
       'opacity-100': animationEnded()
       }" [@fadeSlideLeftRight]="i === 0 ? {value:'', params:{translateX : '50%'}} : {value:'', params:{translateX : '-50%'}}">
    <div class="card-content">
      <div>
        <img [src]="provider.image.thumbnail" alt="Provider thumbnail Image {{provider.name}}" class="thumb">
        <div>
          <p class="buy-voucher">خرید ووچر</p>
          <p class="provider-name">{{provider.name}} <mat-icon svgIcon="chevron_left" /></p>
        </div>
      </div>
      <img [src]="provider.image.src" alt="{{provider.name}} Image" class="provider-img">
    </div>
  </div>
}

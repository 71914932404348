import {Component, signal, ViewEncapsulation} from '@angular/core';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatButton } from '@angular/material/button';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'section-hero',
  standalone: true,
  templateUrl: './section-hero.component.html',
  styleUrl: './section-hero.component.scss',
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatIcon,
    MatButton,
    MatRipple
  ]
})
export class SectionHeroComponent {
  showQRCode = signal(false);
  content = {
    title: 'آسان‌ترین تجربه‌ی خرید ووچر',
    description: {
      styled: 'ووچری،',
      content: 'برای راحتی و دسترسی ایرانیان به تبادلات مالی جهانی'
    }
  }

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      'apk',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/apk.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'bazar',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/bazar.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'play_store',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/play_store.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sib_che',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/sib_che.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'qr_code',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/qr_code.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'qr_code_white',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/qr_code_white.svg')
    );
  }

  toggleQRCode() {
    this.showQRCode.set(!this.showQRCode());
  }
}

import {Component, signal, ViewEncapsulation} from '@angular/core';
import { MatIcon, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {NgClass} from "@angular/common";

interface IProvider {
  name: string;
  image: {
    src: string;
    thumbnail: string;
  }
  featured?: boolean;
}

@Component({
  selector: 'section-services',
  standalone: true,
  imports: [
    MatIcon,
    NgClass
  ],
  templateUrl: './section-services.component.html',
  styleUrl: './section-services.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeSlideLeftRight', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateX({{translateX}})'}),
        animate('1000ms ease-in-out', style({opacity: 1, transform: 'translateX(0)'}))
      ])
    ])
  ],
  host: {
    class: 'relative'
  }
})
export class SectionServicesComponent {
  animationEnded = signal(false);
  providers: IProvider[] = [
    {
      name: 'پینی کد',
      image: {
        src: '/assets/images/providers/pinicode.svg',
        thumbnail: '/assets/images/providers/pinicode_card.svg'
      },
      featured: true,
    },
    {
      name: 'پرفکت مانی',
      image: {
        src: '/assets/images/providers/perfect_money.svg',
        thumbnail: '/assets/images/providers/perfect_money_card.svg'
      }
    },
    {
      name: 'پی سیف کارت',
      image: {
        src: '/assets/images/providers/pay_safe.svg',
        thumbnail: '/assets/images/providers/pay_safe_card.svg'
      }
    }
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    setTimeout(() => {
      this.animationEnded.set(true);
    }, 1000);
    this.matIconRegistry.addSvgIcon(
      'chevron_left',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/chevron_left.svg')
    );
  }
}

import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'section-partners',
  standalone: true,
  imports: [],
  templateUrl: './section-partners.component.html',
  styleUrl: './section-partners.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'block relative w-full overflow-x-hidden'
  }
})
export class SectionPartnersComponent {

}

import {AfterViewInit, Component, inject, ViewEncapsulation} from '@angular/core';
import {DOCUMENT, NgClass, NgStyle, NgTemplateOutlet} from "@angular/common";
import {gsap} from "gsap";
import {MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'section-why',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
    MatButton,
    NgStyle,
    MatIcon
  ],
  templateUrl: './section-why.component.html',
  styleUrl: './section-why.component.scss',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeSlideUp', [
      transition(':enter', [
        style({opacity: 0, transform: 'translateY(50%)'}),
        animate('1s .5s ease-in-out', style({opacity: 1, transform: 'translateX(0)'}))
      ])
    ])
  ]
})
export class SectionWhyComponent {
  private _doc = inject(DOCUMENT)
  services: Service[] = [
    {
      description: 'شما مجبـور به پرداخت هزینـه‌ی کمیسیـون‌های صرافی‌ها نیستید!',
      icon: `/assets/icons/services-1.png`
    },
    {
      description: 'از شـــر تراکنش‌های کند و پلتفرم‌های مشکل و غیــــر شفــاف راحـــت شوید.',
      icon: `/assets/icons/services-2.svg`
    },
    {
      description: 'ووچری به شما کمک می‌کند تا سریع، راحت و شفاف با خرید ووچر به محصولات و خدمات بین‌المللی دسترسی داشته باشید.',
      icon: `/assets/icons/services-3.svg`
    },
    {
      description: 'در این مسیر پشتیبانان ووچری همیشه همراه شما هستند.',
      icon: `/assets/icons/services-4.png`,
      sendMessageLink: true
    },
  ].map((item) => ({
    ...item,
    active: false
  }));
}

type Service = {
  icon: string;
  description: string;
  active: boolean;
  sendMessageLink?: boolean;
}

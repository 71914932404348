import {computed, Injectable, signal} from '@angular/core';
import {isBrowser} from "@app/app.component";

export enum Device {
  IOS,
  Android,
  Web
}

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  device = signal<Device>(Device.Web);
  deviceName = computed(() => {
    switch (this.device()) {
      case Device.Web:
        return 'Web';
      case Device.IOS:
        return 'iOS';
      case Device.Android:
        return 'Android';
      default:
        return 'Unknown';
    }
  })

  constructor() {
    if (isBrowser()) {
      this._detectDevice();
    }
  }

  private _detectDevice() {
    if (this._isIOS()) {
      this.device.set(Device.IOS);
    } else if (this._isAndroid()) {
      this.device.set(Device.Android);
    } else {
      this.device.set(Device.Web);
    }
  }

  private _isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
  }

  private _isAndroid(): boolean {
    return /android/i.test(navigator.userAgent);
  }
}

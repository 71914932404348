import {AfterViewInit, Component, inject} from '@angular/core';
import {gsap} from "gsap";
import {isBrowser} from "@app/app.component";
import {DeviceDetectorService} from "@app/services/device-detector.service";

@Component({
  selector: 'app-download-links',
  standalone: true,
  imports: [],
  templateUrl: './download-links.component.html',
  styleUrl: './download-links.component.scss'
})
export class DownloadLinksComponent implements AfterViewInit {
  private _deviceDetector = inject(DeviceDetectorService);
  ngAfterViewInit() {
    if (isBrowser()) {
      gsap.timeline({
        scrollTrigger: {
          trigger: '#download-links-trigger',
          start: 'top center',
          end: 'top+=20 center',
          scrub: 1
        },
      }).from(
        '#floating-download-links', {
          translateY: '100%',
        }
      ).to(
        '#floating-download-links', {
          translateY: '0%',
        }
      )
    }
  }

  getDevice() {
    alert('Client type is ' + this._deviceDetector.deviceName())
  }
}

<h2 class="relative">
  <div id="steps-anchor" class="absolute -top-8"></div>
  <span>خرید از ووچری</span>
  در ۳ مرحله ساده
</h2>
<div class="our-services">
  @for (service of ourServices; track service.title) {
    <div class="service">
      <img [src]="service.icon" [alt]="service.title">
      <h3>{{service.title}}</h3>
      <p>{{service.description}}</p>
    </div>
  }
</div>
<div class="show">
  <h3>
    از سرعت و سادگی تبادلات مالی خود با جهان لذت ببرید!
  </h3>
  <button mat-button color="primary">
    <span class="buy">
          خرید آسان ووچر
    </span>
    <span class="download">
      دانلود اپلیکیشن
    </span>
  </button>
</div>
